import createApiClient from "./api";

// Create an Axios instance
const client = createApiClient();

export const api = {
  downloadApplication: (campaign_id) =>
    client
      .get(`v1/admin/export-data/application?campaign_id=${campaign_id}`)
      .then(({ data }) => data),

  downloadBillings: () =>
    client.get(`v1/admin/export-data/billings`).then(({ data }) => data),
  downloadBookings: () =>
    client.get(`v1/admin/export-data/booking"`).then(({ data }) => data),

  downloadExams: () =>
    client.get(`v1/admin/export-data/exam`).then(({ data }) => data),

  getExams: () =>
    client.get(`v1/admin/exams?dropdown=1`).then(({ data }) => data),
  getAssessments: () =>
    client.get(`v1/admin/assessments?dropdown=1`).then(({ data }) => data),
  getCampaigns: () =>
    client.get(`v1/admin/campaigns?dropdown=1`).then(({ data }) => data),
  getGroups: () => client.get("v1/admin/groups").then(({ data }) => data),
};
